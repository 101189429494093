$playfair: 'Playfair Display', serif;
$Raleway: 'Raleway', sans-serif;

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background-color: $background-dark;
  color: $light-gray;
}
.container {
  @include xy-grid-container();
  // background-color: $background-dark;
}
blockquote {
  color: $white;
  margin: rem-calc(20 40);
  span {
    font-style: italic;
  }
}
.site-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.footer, .header {
  flex-shrink: 0;
}
.main {
  flex: 1 0 auto;
  background-color: lighten($background-dark, 2);
}
.playTitle {
  font-style: italic;
}
.spaceAbove {
  padding-top: rem-calc(10);
}
.bold {
  font-weight: 800;
}
section.intro, section.myResumeGallery {
  padding-top: rem-calc(100);
}
section.intro, section.resumeHeadshots, section.gallery {
  padding-bottom: rem-calc(100);
}
section.image-background {
  padding-top: rem-calc(60);
}
section.contactLink {
  color: $primary-color;
  border-top: 1px solid lighten($background-dark, 14);
}
section.contactForm {
  label {
    color: $primary-color;
  }
}
section.intro {
  border-top: 1px solid lighten($background-dark, 14);
  border-bottom: 1px solid lighten($background-dark, 14);
}
section.copyright-credits {
  color: $white;
  border-top: 1px solid lighten($background-dark, 14);
}
.transparent-band {
  background-color: rgba(0, 0, 0, .4);
  height: rem-calc(30);
  margin-top: rem-calc(-30);
  z-index: 1;
  border-top: 1px solid lighten($background-dark, 10);
}
header.header {
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid lighten($background-dark, 10);
  .title-bar {
    @include xy-grid();
    @include flex-align(justify, middle);
    height: rem-calc(60);
    .myTitleBarName {
      @include xy-cell(8);
      h2  {
        @include font-bold(800);
        text-transform: uppercase;
        font-size: rem-calc(16);
      }
    }
    .menuContainer {
      @include xy-cell(4);
      text-align: right;
    }
  }
  .headerContent {
    @include xy-grid();
    @include flex-align(justify, middle);
    // height: rem-calc(50);

    .myName {
      @include xy-cell(4);
      @include show-for(medium);
      h2  {
        @include font-bold(800);
        text-transform: uppercase;
        font-size: rem-calc(18);
        margin-bottom: rem-calc(0);
      }
    }
    .menu {
      @include menu-base();
      @include xy-cell(12);
      @include flex-align(right, middle);
      li {
        @include breakpoint(small only) {
          text-align: right;
          width: 100%;
        }
      }
      li a {
        @include font-bold(700);
        font-size: rem-calc(12);
        text-transform: uppercase;

        @include breakpoint(medium) {
          font-size: rem-calc(14);
        }
      }
      @include breakpoint(medium) {
        @include xy-cell(8);
      }
    }
    @include breakpoint(medium) {
      height: rem-calc(60);
    }
  }
}
section.image-background {
  background-color: #202425;
  .image-container {
    position: relative;
    .image-content {
      // position: absolute;
      // top: 10%;
      // left: 20px;
      @include vertical-center();
        h1 {
          color: $primary-color;
          font-size: rem-calc(32);
          font-weight: 500;
          font-family: $playfair;
        }
        h4 {
          color: $white;
          font-size: rem-calc(14);
          text-transform: uppercase;
          padding-left: rem-calc(4);
        }
      @include breakpoint(medium) {
        h1 {
          color: $primary-color;
          font-size: rem-calc(60);
          font-family: $playfair;
        }
        h4 {
          color: $white;
          font-size: rem-calc(16);
          text-transform: uppercase;
        }
      }
    }
    .image-content-caption {
      display: flex;
      justify-content: center;
      margin-bottom: rem-calc(30);

      h3  {
        color: $white;
        font-size: rem-calc(12);
        text-align: center;
      }
    }
  }
  .transparent-band {
    background-color: rgba(0, 0, 0, .4);
    height: rem-calc(30);
    margin-top: rem-calc(-30);
    z-index: 1;
    border-top: 1px solid lighten($background-dark, 10);
  }
}
section.intro {
  padding: rem-calc(30 0);
  h2, h3 {
    font-family: $playfair;
  }
  h3, h4, p {
    font-family: $Raleway;
  }
  .more {
    font-weight: 700;
    font-size: rem-calc(20);
  }
	h2 {
		font-size: rem-calc(24);
    color: $primary-color;
    font-weight: 700;
    margin-bottom: rem-calc(14);
	}
  h3 {
    font-size: rem-calc(20);
    margin-bottom: rem-calc(20);
    span.playWrite {
      font-size: rem-calc(15);
    }
  }
  h4 {
    font-size: rem-calc(15);
    padding-bottom: rem-calc(10);
    border-bottom: 1px solid lighten($background-dark, 30);
  }
  p {
    font-size: rem-calc(15);
    a {
      font-weight: 900;
    }
  }
	@include breakpoint(medium) {
		padding: rem-calc(90 0);
    .more {
      font-size: rem-calc(22);
    }
		h2 {
			font-size: rem-calc(32);
		}
    h3 {
      font-size: rem-calc(24);
      span.playWrite {
        font-size: rem-calc(18);
      }
    }
    h4 {
      font-size: rem-calc(18);
    }
    p {
      font-size: rem-calc(18);
    }
	}
}
section.contactLink {
  @include text-transform(uppercase);
  text-align: center;
	padding: rem-calc(30 0);
  h3 {
    font-size: rem-calc(22);
    @include font-bold(900);
  }
	@include breakpoint(medium) {
		padding: rem-calc(40 0);
	}
}
section.contactForm {
    #form-messages {
      @include font-bold(400);
      padding: rem-calc(3);
      font-size: rem-calc(20);
      margin-bottom: rem-calc(8);
    }
    .success {
      color: $white;
      border: 1px solid $white;
    }
    .error {
      color: rgb(236, 88, 64);
      border: 1px solid rgb(236, 88, 64);
    }

  .myFormContainer {
    @include xy-grid();
    label {
      @include font-bold(700);
      text-transform: uppercase;
      font-size: rem-calc(18);
      color: $primary-color;
    }
    .button {
      @include font-bold(700);
      text-transform: uppercase;
      font-size: rem-calc(18);
      margin: rem-calc(10);
    }
    .myNameField, .myEmailField {
      @include xy-cell(6);
    }
    .myMessageField {
      @include xy-cell(12);
    }
  }
}
section.copyright-credits {
	padding-top: rem-calc(8);
	p.copyright {
    padding-top: rem-calc(6);
		color: darken($white, 18);
		font-size: rem-calc(12);
	}
	@include breakpoint(medium) {
		text-align: left;
		p {
			text-align: left;
			font-size: rem-calc(24);
		}
	}
}
section.resumeHeader {
  background-color: #000000;
  .image-container {
    display: flex;
    justify-content: center;
    img {
      max-height: 55vh;
    }
  }
}
section.gallery {
  display: flex;
  justify-content: center;
  div.gallery-container {
    margin-top: rem-calc(100);
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem-calc(20);
    .horizontal {
        width: 100%;
    }
    .vertical {
      min-width: 33%;
    }
    .img-wrap {
      text-align: center;
      // max-width: 100%;
      img {
        max-height: 800px;
        width: auto;
      }
      p {
        text-align: center;
        font-weight: 600;
        font-size: rem-calc(12);
        color: $light-gray;
      }
    }
  }
}
// section.gallery {
//   display: flex;
//   justify-content: center;
//   .gallery-container {
//     margin-top: rem-calc(100);
//     width: 80%;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     gap: rem-calc(20);
//
//     .img-wrap.vertical {
//       width: 45%;
//       @include breakpoint(large) {
//         max-width: 20%;
//       }
//     }
//     .img-wrap {
//       max-width: 100%;
//       margin-bottom: rem-calc(20);
//       @include breakpoint(large) {
//         max-width: 85%;
//       }
//       img {
//       }
//       p {
//         font-size: rem-calc(12);
//         font-weight: 600;
//         text-align: center;
//         color: $light-gray;
//       }
//     }
//   }
// }
section.myGallery, section.myResumeGallery {
	/* ---- isotope ---- */
  .grid {
  	// min-height: rem-calc(440);
  }
  /* clear fix */
  .grid:after {
  	content: '';
  	display: block;
  	clear: both;
  }
  .grid-item {
    width: 50%;
    padding: rem-calc(10);
    	@include breakpoint(medium) {
    	width: 33.333%;
    	}
      .img-wrap {
        position: relative;
        overflow: hidden;
        border: 1px solid lighten($background-dark, 10);
        img {
          display: block;
          max-width: 100%;
          -webkit-transition: .5s ease-in-out;
          -moz-transition: .5s ease-in-out;
          -o-transition: .5s ease-in-out;
        }
        img:hover {
          transform: scale(1.05);
          -webkit-transform: scale(1.05);
        }
      }
  }
  .headshot {
    @include breakpoint(medium) {
      padding-bottom: rem-calc(80);
    }
  }
  .double {
    @include breakpoint(medium) {
      width: 66%;
    }
  }
}
section.myResumeGallery {
  @include breakpoint(small only) {
    .grid-item {
      width: 33.333%;
    }
  }
}
section.resumeHeadshots {
  .outer-grid {
    @include xy-grid();
    .tableColumn {
      background: transparent;
      @include xy-cell(12);
      @include breakpoint(medium) {
        @include xy-cell(12);
      }
      @include breakpoint(large) {
        @include xy-cell(12);
      }
      .resumeTable {
        background: transparent;
        thead:first-child .tableHeading th {
          padding-top: rem-calc(0);
          padding-bottom: rem-calc(2);
           }
        thead, tbody {
          background: transparent;
          border: none;
          vertical-align: top;
          tr {
            background: transparent;
            border-bottom: 1px solid $medium-gray;
          }
          tr.sm-RowHead {
            @include show-for-only(small);
            td {
              @include font-bold(700);
              text-transform: uppercase;
              color: $primary-color;
              font-size: rem-calc(18);
              padding-top: rem-calc(28);
            }
            td:first-child {
              font-style: normal;
            }
          }
          tr td:first-child {
            font-style: italic;
          }
          tr.concert td, tr.training td {
            font-style: normal;
          }
          td {
            padding: rem-calc(2 10 2);
            span.sm-Head {
              @include font-bold(700);
              @include show-for-only(small);
              font-style: normal;
              color: $primary-color;
            }
          }
          th {
            color: $white;
            // background-color: #202425;
            font-size: rem-calc(14);
            @include text-transform(uppercase);
          }
          .tableHeading {
            border: none;
            th {
              padding: rem-calc(40 8 2);
              font-size: rem-calc(18);
              color: $primary-color;
            }
          }
        }
      }
    }
  }
}
